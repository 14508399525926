import { getThumbnailUrlFromVideoStreamUrl } from '@arcadehq/shared/helpers/mux'
import { Step, StepType } from '@arcadehq/shared/types'

export function getStepPreviewUrl(step: Step | undefined): string {
  if (!step) {
    return '/images/empty-flow.svg'
  }
  if (step.type === StepType.Image) {
    return step.url
  }

  if (step.type === StepType.Video) {
    return (
      step.videoThumbnailUrl ||
      getThumbnailUrlFromVideoStreamUrl(step.streamUrl || step.url)
    )
  }

  return '/images/empty-flow.svg'
}
